/* Made with love by Mutiullah Samim*/

@import url('https://fonts.googleapis.com/css?family=Numans');

html,body{
  
/* background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg'); */
/* background-image: url('https://i.ibb.co/BcnM85D/web-3963945-1920.jpg'); */
/* background-image: url('https://png.pngtree.com/thumb_back/fh260/back_pic/03/99/28/3857f8699bb8f75.jpg'); */
background-image: url('prakriti_back.jpg');
/* background-image: url('https://giphy.com/embed/5owNSuvkqgLg1iqNrF');; */
background-size: cover;
background-repeat: no-repeat;
height: 100%;
font-family: 'Numans', sans-serif;

}

.container{
height: 100%;
align-content: center;
}

.card{
height: 300px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
background-color: rgba(0,0,0,0.5) !important;
}

.card1{
    height: 340px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0,0,0,0.5) !important;
    }

.social_icon input{
/* font-size: 20px; */
margin-left: 10px;
/* margin-top: 80px; */
color: #FFC312;
}

.social_icon span:hover{
color: white;
cursor: pointer;
}

.card-header h3{
color: white;
}

.social_icon{
position: absolute;
/* right: 20px; */
right: 0px;
top: -15px;
}

.input-group-prepend span{
width: 50px;
/* background-color: #FFC312;
color: black; */
/* background-color: white;
color: 'grey'; */
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;

}



.remember{
color: white;
}

.remember input
{
width: 20px;
height: 20px;
margin-left: 15px;
margin-right: 5px;
}


.login_btn{
color: white;
/* background-color: #FFC312; */
background:#214a80;
/* width: 360px; */
width: 100px;
}

.login_btn:hover{
color: black;
background-color: white;
}



.links{
color: white;
}

.links a{
margin-left: 4px;
}


  
  
  
  .appForm {
    width: 23%;
    /* background-color: #12130f; */
    padding: 25px 40px;
    overflow: auto;
  }
  
  .pageSwitcher {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10%;
  }
  
  .pageSwitcherItem {
    /* background-color: #40434e; */
    background-color: rgba(0,0,0,0.5) !important;
    color: #9da6b1;
    padding: 10px 25px;
    cursor: pointer;
    font-size: 0.9em;
    border: none;
    outline: none;
    display: inline-block;
    text-decoration: none;
  }
  
  .pageSwitcherItem-active {
    background-color: #6b5b95;
    color: white;
  }
  
  .pageSwitcherItem:first-child {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  .pageSwitcherItem:last-child {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
  
  .formCenter {
    margin-bottom: 100px;
  }
  
  .formTitle {
    color: #707c8b;
    font-weight: 300;
    margin-bottom: 50px;
  }
  
  .formTitleLink {
    color: #707c8b;
    text-decoration: none;
    display: inline-block;
    font-size: 1.7em;
    margin: 0 10px;
    padding-bottom: 5px;
  }
  
  .formTitleLink:first-child {
    margin-left: 0;
  }
  
  .formTitleLink-active {
    color: white;
    border-bottom: 1px solid #6b5b95;
  }
  
  .formField {
    margin-bottom: 40px;
  }
  
  .formFieldLabel {
    display: block;
    text-transform: uppercase;
    font-size: 0.9em;
    color: white;
  }
  .formFieldInput {
    width: 85%;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    border-bottom: 1px solid #445366;
    font-size: 1em;
    font-weight: 300;
    padding-bottom: 10px;
    margin-top: 10px;
  }
  
  .formFieldInput::placeholder {
    color: #616e7f;
  }
  
  .formFieldButton {
    background-color: #6b5b95;
    color: white;
    border: none;
    outline: none;
    border-radius: 25px;
    padding: 15px 70px;
    font-size: 0.8em;
    font-weight: 500;
  }
  
  .formFieldLink {
    color: #66707d;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1.5px solid #6b5b95;
    padding-bottom: 5px;
  }
  
  .formFieldCheckboxLabel {
    color: #646f7d;
    font-size: 0.9em;
  }
  
  .formFieldCheckbox {
    position: relative;
    top: 1.5px;
  }
  
  .formFieldTermsLink {
    color: white;
    border-bottom: 1px solid #6b5b95;
    text-decoration: none;
    display: inline-block;
    padding-bottom: 2px;
    margin-left: 5px;
  }
  
  .socialMediaButtons {
    padding: 10px 100px 10px 0px;
  }
  

  .container-fluid{
    width: 85%;
   
    margin: 0 auto; /* Center the DIV horizontally */
}
.fixed-header, .fixed-footer{
    width: 100%;
    /* height:10vh; */
    position: fixed;   
    /* display:inline;   */
    border: '1px solid #eee';
    box-shadow: 2px 5px #ebebeb;  
    /* background: #333; */
    padding: 8px 0;
    color: #fff;
}
.fixed-header{
    top: 0;
}
.fixed-footer{
    bottom: 0;
}    
/* Some more styles to beutify this example */
nav a{
    color: #fff;
    text-decoration: none;
    padding: 7px 25px;
    display: inline-block;
}
.container p{
    line-height: 200px; /* Create scrollbar to test positioning */
}

  .icon-bar {
    width: 90px;
    height: 99vh;
    /* background-color: #555; */
    background-color:'rgba(255, 255, 255, 1)';
    border: '1px solid #eee';
    box-shadow: 2px 10px #ebebeb;
        /* background-color: rgba(0, 0, 0, 0.1) !important; */
  }
  
  .icon-bar img {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: black;
    /* margin-left:15px; */
    margin-top: 5px;

    /* font-size: 36px; */
    width: 90px;
    
   
  }
  
  .icon-bar img:hover:not(.active) {
    background-color: rgb(131, 150, 169);
    color: white;
  }
  

  /* .icon-bar :hover {
    width: 70px;
    background-color: grey;
  } */
  
  /* .icon-bar img.active {
    background-color: #04AA6D;
    color: white;
  } */
  
  .icon-bar img.active{
    /* width: 70px; */
    /* border-bottom: 3px solid #338ecf; */
    background-color: rgb(131, 150, 169);
    color: white;
    /* background-color: #04AA6D; */
    /* background-color: rgba(0, 0, 0, 0.9) !important; */
  }